style = $.cookie('style');
font = $.cookie('font');
if (style === "wcagBlack") {
    wcagBlackStyle(document.createEvent('Event'));
}

if (style === "wcagYellow") {
    wcagYellowStyle(document.createEvent('Event'));
}

if (font === "medium") {
    mediumFont(document.createEvent('Event'));
}
if (font === "large") {
    largeFont(document.createEvent('Event'));
}

function normalStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet.css');
    $.cookie('style', 'normal', {
        expires : 365,
        path    : '/'
    });
}

function wcagBlackStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-black.css');
    $.cookie('style', 'wcagBlack', {
        expires : 365,
        path    : '/'
    });
}

function wcagYellowStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-yellow.css');
    $.cookie('style', 'wcagYellow', {
        expires : 365,
        path    : '/'
    });
}


function smallFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-small';
    $.cookie('font', 'small', {
        expires : 365,
        path    : '/'
    });
}

function mediumFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-medium';
    $.cookie('font', 'medium', {
        expires : 365,
        path    : '/'
    });
}

function largeFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-large';
    $.cookie('font', 'large', {
        expires : 365,
        path    : '/'
    });
}

$(document).ready(function () {


    $('.wcag__small').click(function(){
        $(this).addClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__large').removeClass('active');
    })

    $('.wcag__medium').click(function(){
        $(this).addClass('active');
        $('.wcag__small').removeClass('active');
        $('.wcag__large').removeClass('active');
    })

    $('.wcag__large').click(function(){
        $(this).addClass('active');
        $('.wcag__small').removeClass('active');
        $('.wcag__medium').removeClass('active');
    })

    $('.wcag__normal').click(function(){
        $(this).addClass('active');
        $('.wcag__blackContrast').removeClass('active');
        $('.wcag__yellowContrast').removeClass('active');
    })

    $('.wcag__blackContrast').click(function(){
        $(this).addClass('active');
        $('.wcag__normal').removeClass('active');
        $('.wcag__yellowContrast').removeClass('active');
    })

    $('.wcag__yellowContrast').click(function(){
        $(this).addClass('active');
        $('.wcag__normal').removeClass('active');
        $('.wcag__blackContrast').removeClass('active');
    })




    if ($.cookie('style') == undefined) {

        $('.wcag__blackContrast').removeClass('active');
        $('.wcag__yellowContrast').removeClass('active');
        $('.wcag__normal').addClass('active');
    }

    if ($.cookie('style') == 'wcagBlack') {
        $('.wcag__blackContrast').addClass('active');
        $('.wcag__yellowContrast').removeClass('active');
        $('.wcag__normal').removeClass('active');
    }

    if ($.cookie('style') == 'wcagYellow') {
        $('.wcag__yellowContrast').addClass('active');
        $('.wcag__blackContrast').removeClass('active');
        $('.wcag__normal').removeClass('active');
    }

    if ($.cookie('style') == 'normal') {
        $('.wcag__blackContrast').removeClass('active');
        $('.wcag__yellowContrast').removeClass('active');
        $('.wcag__normal').addClass('active');
    }


    if ($.cookie('font') == undefined) {
        $('.wcag__small').addClass('active');
    }


    if ($.cookie('font') == 'small') {
        $('.wcag__large').removeClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__small').addClass('active');
    }

    if ($.cookie('font') == 'medium') {
        $('.wcag__large').removeClass('active');
        $('.wcag__medium').addClass('active');
        $('.wcag__small').removeClass('active');
    }

    if ($.cookie('font') == 'large') {
        $('.wcag__large').addClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__small').removeClass('active');
    }

});